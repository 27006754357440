import useIsElementInView from "@/hooks/useIsElementInView";
import { globalConfig } from "@/services/globalConfig/globalConfigService";
import { useAppSelector } from "@/store/store";
import "animate.css";
import clsx from "clsx";
import { useCallback, useEffect, useState } from "react";

interface AnimationProps {
  animationIn?: string;
  animationOut?: string;
  animationHover?: string;
  children: React.ReactNode;
}

const Animation = (props: AnimationProps) => {
  const [element, setElement] = useState();
  const [animationWasTriggered, setAnimationWasTriggered] = useState(false);
  const [elementWasVisible, setElementWasVisible] = useState(false);
  const [previousVisibility, setPreviousVisibility] = useState("hidden");
  // initial means the first (on load) visible elements when you enter a page
  const { isInView, isInitialyInView, isAnimationForInitialElementsEnabled } =
    useIsElementInView(element);
  const editView = useAppSelector((state) => state.cmsGeneral.editView);

  const handleRect = useCallback((node: any) => {
    setElement(node);
  }, []);

  const initialAnimationToggle =
    globalConfig?.animation?.triggerInitialAnimation || false;

  // checks all cases that are required for an element to trigger an animation
  const animationEnabled =
    !isInitialyInView ||
    initialAnimationToggle ||
    (!initialAnimationToggle &&
      isInitialyInView &&
      isAnimationForInitialElementsEnabled);

  const triggerFadeInAnimation = animationEnabled && isInView;
  const triggerFadeOutAnimation = !isInView && animationWasTriggered;

  // animations should only trigger on user scroll
  // all scrollTo functions disable animations
  // this enables animations whenever a scroll event has ended
  useEffect(() => {
    let scrollTimeout: NodeJS.Timeout;
    addEventListener("scroll", function (e) {
      clearTimeout(scrollTimeout);
      scrollTimeout = setTimeout(function () {
        document
          .getElementById("layout")
          ?.setAttribute("data-tempdisableanimations", "false");
      }, 100);
    });
  }, []);

  // independet (from settings etc) check if the element was visible and hidden afterwards
  // only required if no fadeout was chosen and the elements should stay
  // animationWasTriggered can be true without any animation triggered if the setting
  // triggerInitialAnimation is false
  useEffect(() => {
    if (isInView && previousVisibility === "hidden") {
      setPreviousVisibility("visible");
    } else if (
      !isInView &&
      previousVisibility === "visible" &&
      !animationWasTriggered
    ) {
      setPreviousVisibility("hidden");
      setAnimationWasTriggered(true);
    }
  }, [isInView, previousVisibility, isInitialyInView, animationWasTriggered]);

  // checks if the element is visible or not considering the settings
  // and sets the via cfg chosen animation as class on the animation wrapper element
  // setting the class will trigger the animation immediately
  const getAnimationClass = () => {
    if (typeof window !== "undefined") {
      const layoutEl = document.getElementById("layout");
      if (layoutEl?.getAttribute("data-tempdisableanimations") === "true") {
        return;
      }
    }
    if (props.animationOut && props.animationOut !== "none") {
      if (editView) {
        return "";
      } else if (triggerFadeInAnimation) {
        if (!elementWasVisible) {
          setElementWasVisible(true);
        }
        return `animate__animated animate__${props.animationIn}`;
      } else if (triggerFadeOutAnimation) {
        return `animate__animated animate__${props.animationOut}`;
      } else if (animationEnabled) {
        // any element that will trigger an animation needs to be hidden first
        return "hidden";
      } else {
        return "";
      }
    } else if (props.animationOut === "none" || !props.animationOut) {
      if (editView || animationWasTriggered) {
        return "";
      } else if (triggerFadeInAnimation) {
        if (!elementWasVisible) {
          setElementWasVisible(true);
        }
        return `animate__animated animate__${props.animationIn}`;
      } else if (animationEnabled) {
        // any element that will trigger an animation needs to be hidden first
        return "hidden";
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  return (
    <>
      <div ref={handleRect} className="animation-wrapper">
        <div
          className={clsx(
            getAnimationClass(),
            elementWasVisible && "was-visible",
            !editView && props.animationHover
          )}
        >
          {props.children}
        </div>
      </div>
      <style jsx>
        {`
          .animation-wrapper {
            // this can be used to prevent any scrollbars that can potentially happen
            // but it will cause all animations to only happen in the wrapper
            // which can be smaller than the pagewidth because of spaceX etc
            // overflow: hidden;
          }
          .hidden:not(.was-visible) {
            visibility: hidden;
          }
        `}
      </style>
      <style>{`
        /* remove overflow from main if any animation is active to prevent horizontal scrollbars */
        body .main {
          overflow: hidden;
        }
      `}</style>
    </>
  );
};

export default Animation;
