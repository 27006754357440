import { globalConfig } from "@/services/globalConfig/globalConfigService";
import { useAppSelector } from "@/store/store";
import { useCallback, useEffect, useState } from "react";


export default function useIsElementInView(element: any) {
  const [isInView, setIsInView] = useState(false);
  const [isInitialyInView, setIsInitialyInView] = useState<boolean | null>(null);
  const [
    isAnimationForInitialElementsEnabled,
    setIsAnimationForInitialElementsEnabled,
  ] = useState(false);
  const isCmsUserAuthenticated = useAppSelector(
    (state) => state.cmsUser.isCmsUserAuthenticated
  );

  const checkIfElementIsInView = useCallback(() => {
    if (typeof window !== "undefined" && element) {
      const fadeGap =
        globalConfig && globalConfig.animation
          ? globalConfig.animation.triggerGap
          : window.innerHeight * 0.1;
      const navigationElement = document.getElementById("navbar");
      const topBar = document.getElementById("cms-top-bar");
      const navigationHeight = navigationElement
        ? navigationElement.offsetHeight
        : 0;
      const topBarHeight = topBar ? topBar.offsetHeight : 0;
      const cmsTopBarAndNavigationHeight = isCmsUserAuthenticated
        ? navigationHeight + topBarHeight
        : navigationHeight;

      // if the gap is smaller than the gap we overwrite it for the elemt with its size
      // to allow any element to successfully trigger a visible animation
      const elementSizeIfSmallerThanGap =
        element.offsetHeight < fadeGap ? element.offsetHeight : fadeGap;
      const pageTop =
        window.scrollY +
        elementSizeIfSmallerThanGap +
        cmsTopBarAndNavigationHeight; // add navigation height if neccessary here
      const pageBottom =
        window.scrollY + window.innerHeight - elementSizeIfSmallerThanGap;

      const elementTop = element.getBoundingClientRect().top + window.scrollY;
      const elementBottom =
        element.getBoundingClientRect().bottom + window.scrollY;

      const isElementInView =
        elementTop <= pageBottom && elementBottom >= pageTop;

      if (isInView !== isElementInView) {
        setIsInView(isElementInView);
      }

      // this part is only relevant if the setting triggerInitialAnimation
      // is on false
      if (isInitialyInView === null) {
        setIsInitialyInView(isElementInView);
      } else if (
        isInitialyInView &&
        !isElementInView &&
        !isAnimationForInitialElementsEnabled
      ) {
        setIsAnimationForInitialElementsEnabled(true);
      }
    }
  }, [
    element,
    isInView,
    isCmsUserAuthenticated,
    isInitialyInView,
    isAnimationForInitialElementsEnabled,
  ]);

  useEffect(() => {
    window.addEventListener("scroll", checkIfElementIsInView, {
      passive: true,
    });
    checkIfElementIsInView();
    // remove event on unmount to prevent a memory leak
    return () =>
      window.removeEventListener("scroll", checkIfElementIsInView);
  }, [checkIfElementIsInView]);

  return { isInView, isInitialyInView, isAnimationForInitialElementsEnabled };
}
